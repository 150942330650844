/* eslint-disable max-lines */
/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listLineItemsGql = gql`
  query listLineItems(
    $limit: Int = 25
    $offset: Int = 0
    $filter: ListLineItemsFilter
    $sortBy: LineItemsSortBy
  ) {
    listLineItems(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      results: result {
        lineItem {
          requestLineItemId
          taggedForReview
          requestedDate
          needsByDate
          status
          item {
            ... on TransferRequest {
              patientMedication {
                name
              }
            }
          }
        }
        patient {
          mpi: patientId
          patientDemographics {
            firstName
            lastName
          }
        }
        partner {
          partnerName
        }
      }
    }
  }
`;

export const controlledSubstancesCheckGql = gql`
  mutation controlledSubstancesCheck($checkInput: ControlledSubstanceCheckInput!) {
    controlledSubstancesCheck(input: $checkInput) {
      requestLineItemId
      status
      reviewReason
      taggedForReview
    }
  }
`;

export const reviewLineItemGql = gql`
  mutation reviewLineItem(
    $lineItemId: UUID!
    $reviewResult: Boolean!
    $mpi: UUID!
    $reasonForRejection: String
    $qs1RxNumber: String
  ) {
    reviewLineItem(
      lineItemId: $lineItemId
      reviewResult: $reviewResult
      mpi: $mpi
      reasonForRejection: $reasonForRejection
      qs1RxNumber: $qs1RxNumber
    ) {
      requestLineItemId
      status
      needsByDate
      requestedDate
      orderId
      item {
        ... on TransferRequest {
          patientMedication {
            name
          }
        }
      }
    }
  }
`;

export const prescriberClarificationCheckGql = gql`
  mutation prescriberClarificationCheck($checkInput: PrescriberClarificationInput!) {
    prescriberClarificationCheck(input: $checkInput) {
      requestLineItemId
      status
      taggedForReview
      reviewReason
    }
  }
`;

export const copayResultCheckGql = gql`
  mutation copayResultCheck($checkInput: CopayResultCheckInput!) {
    copayResultCheck(input: $checkInput) {
      requestLineItemId
      status
      taggedForReview
      reviewReason
    }
  }
`;

export const profitabilityResultCheckGql = gql`
  mutation profitabilityResultCheck($checkInput: ProfitabilityResultCheckInput!) {
    profitabilityResultCheck(input: $checkInput) {
      requestLineItemId
      status
      taggedForReview
      reviewReason
    }
  }
`;

export const getRequestLineItemVerificationDataGql = gql`
  query getRequestLineItemVerificationData($mpi: UUID!, $requestLineItemId: UUID!) {
    getRequestLineItemVerificationData(mpi: $mpi, requestLineItemId: $requestLineItemId) {
      prescriber {
        name
        npi
        dea
        deaState
        phoneNumber
      }
      requestLineItem {
        requestLineItemId
        estimatedFulfillmentDate
        needsByDate
        qs1RxNumber
        copay
        item {
          ... on TransferRequest {
            patientMedication {
              name
              ndc
              strength
              form
              quantity
              instructions
              dawCode
            }
          }
        }
      }
      allergies {
        allergyType
        description
        patientAllergyId
      }
    }
  }
`;

export const verifyRequestLineItemGql = gql`
  mutation verifyRequestLineItem($input: VerifyRequestLineItemInput!) {
    verifyRequestLineItem(input: $input) {
      requestLineItemId
      status
      taggedForReview
      reviewReason
      needsByDate
      requestedDate
      orderId
      status
      qs1RxNumber
      item {
        ... on TransferRequest {
          patientMedication {
            name
            form
            strength
            quantity
          }
        }
      }
    }
  }
`;

export const listBulkLabelsGql = gql`
  query listBulkLabels(
    $limit: Int = 25
    $offset: Int = 0
    $filter: ListBulkLabelFilter
    $sortBy: BulkLabelSortBy
  ) {
    listBulkLabels(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      results: result {
        lineItemId: requestLineItemId
        lineItemStatus
        isTaggedForReview
        patient {
          mpi: patientId
          patientDemographics {
            firstName
            lastName
          }
        }
        lastPrintedDate
        needsByDate
        createdAt
        medicationName
        fillType
        orderId
      }
    }
  }
`;

export const generateBulkLabelsGql = gql`
  mutation generateBulkLabels($requestLineItemIds: [String!]!) {
    generateBulkLabels(requestLineItemIds: $requestLineItemIds)
  }
`;

export const markLineItemsPrintedGql = gql`
  mutation markLineItemsPrinted($lineItemIds: [String!]!, $markProductsFilled: Boolean) {
    markLineItemsPrinted(lineItemIds: $lineItemIds, markProductsFilled: $markProductsFilled)
  }
`;

export const listOrdersForProductFulfillmentGql = gql`
  query listOrdersForProductFulfillment(
    $limit: Int = 25
    $offset: Int = 0
    $filter: OrdersFilter
    $sortBy: OrdersSortBy
  ) {
    listOrdersForProductFulfillment(
      limit: $limit
      offset: $offset
      filter: $filter
      sortBy: $sortBy
    ) {
      count
      orders {
        orderId
        patient {
          mpi: patientId
          patientIdentity {
            qs1PatientId
          }
          patientDemographics {
            firstName
            lastName
          }
        }
        requestId
        requestNeedsByDate
        orderStatus
        partner {
          partnerName
        }
        partnerAccountId
      }
    }
  }
`;

export const completeEntryGql = gql`
  mutation completeEntry($mpi: UUID!, $lineItemId: UUID!, $state: RequestLineItemStatus!) {
    completeEntry(mpi: $mpi, lineItemId: $lineItemId, state: $state) {
      requestLineItemId
      status
    }
  }
`;

export const assertQs1RxNumberGql = gql`
  mutation assertQs1RxNumber($rxNumber: String!, $mpi: UUID!, $lineItemId: UUID!) {
    assertQs1RxNumber(rxNumber: $rxNumber, mpi: $mpi, lineItemId: $lineItemId) {
      qs1RxNumber
      requestLineItemId
      status
      taggedForReview
      reviewReason
    }
  }
`;

export const updateRequestLineItemStateGql = gql`
  mutation updateRequestLineItemState(
    $mpi: UUID!
    $requestId: UUID!
    $lineItemId: UUID!
    $currentState: RequestLineItemStatus!
    $skipState: Boolean
    $rollbackState: Boolean
  ) {
    updateRequestLineItemState(
      mpi: $mpi
      requestId: $requestId
      lineItemId: $lineItemId
      currentState: $currentState
      skipState: $skipState
      rollbackState: $rollbackState
    ) {
      requestLineItemId
      status
    }
  }
`;

export const bulkFillGql = gql`
  mutation bulkFill($lineItemIds: [String!]!) {
    bulkFill(lineItemIds: $lineItemIds)
  }
`;

export const listEVisitRequestsGql = gql`
  query listAwaitingEVisitRequests(
    $limit: Int = 25
    $offset: Int = 0
    $filter: AwaitingEVisitRequestFilter
    $sortBy: AwaitingEVisitRequestSortBy
  ) {
    listAwaitingEVisitRequests(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      results: result {
        eVisitRequestId
        status
        partner {
          partnerUuid
          partnerName
        }
        requestedDate
        campaignBundle {
          bundleLabel
          bundleCost
        }
        patient {
          mpi
          firstName
          lastName
        }
      }
    }
  }
`;

export const listRxIntakeRequestsGql = gql`
  query listRxIntakeRequests(
    $limit: Int
    $offset: Int
    $filter: ListRxIntakeRequestsFilter
    $sortBy: ListRxIntakeRequestsSortBy
  ) {
    listRxIntakeRequests(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result {
        patient {
          firstName
          lastName
        }
        status
        prescriptions {
          description
        }
        practice {
          practiceId
        }
        createdAt
        scriptId
        requestPayload {
          enrichedPrescriptionPayload
          eScriptMetaData
          originalPayload
        }
        partnerAccountId
        partnerId
      }
    }
  }
`;

export const importEScriptRequestsGql = gql`
  mutation importEScriptOrders($input: [ImportEScriptOrderInput!]!) {
    importEScriptOrders(input: $input)
  }
`;

export const closeEScriptRequestsGql = gql`
  mutation closeEScriptOrders($eScriptIds: [String!]!) {
    closeEScriptOrders(eScriptIds: $eScriptIds)
  }
`;
