import { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { map } from 'lodash';
import {
  validateEmailArray,
  validateMinLength,
  validateZipCode,
} from 'common/forms/formValidations';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getPartners } from 'store/thunks/partnerThunks';
import { createPractice } from 'store/thunks/practiceThunks';
import { Form } from 'react-final-form';
import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { formatZipCode } from 'common/utils';
import LoadingButton from '@mui/lab/LoadingButton';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import AutoCompleteMultipleSelect from 'common/forms/AutoCompleteMultipleSelect';

const AddPracticeButton = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModel] = useState(false);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const handleOpenModal = () => setShowModel(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModel(false);
    }
  };

  useEffect(() => {
    if (!partners) {
      dispatch(getPartners());
    }
  }, [dispatch, partners]);

  const handleAddPractice = (formData) => {
    const practiceData = {
      practiceInput: {
        practiceName: formData.practiceName,
        address: {
          line1: formData.addressLine1,
          line2: formData.addressLine2,
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
        },
        contact: { emails: formData.contactEmails },
      },
      partnerUUID: formData.partner,
    };

    return dispatch(createPractice({ practiceData })).then((response) => {
      if (response) {
        enqueueSnackbar('Practice profile created successfully', { variant: 'success' });
        setShowModel(false);
      }
    });
  };

  return (
    <>
      <Button
        sx={{ height: 33, mr: 2 }}
        variant='contained'
        color='primary'
        onClick={handleOpenModal}
      >
        Add New
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PracticeManagement-AddPracticeButton-header'
        fullWidth
      >
        <DialogTitle id='PracticeManagement-AddPracticeButton-header'>Add Practice</DialogTitle>

        <Form
          onSubmit={handleAddPractice}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <SelectField
                  name='partner'
                  id='PracticeManagement-AddPracticeButton-gender-dropdown'
                  label='Select Partner'
                  required
                >
                  {map(partners, ({ partnerName, partnerUuid }) => (
                    <MenuItem value={partnerUuid} key={partnerUuid}>
                      {partnerName}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PracticeManagement-AddPracticeButton-practiceName-input'
                  label='Practice Name'
                  name='practiceName'
                  required
                  autoFocus
                  validations={[validateMinLength('Minimum of 3 characters', 3)]}
                />

                <TextField
                  id='PracticeManagement-AddPracticeButton-address1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />

                <TextField
                  id='PracticeManagement-AddPracticeButton-address2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField
                  id='PracticeManagement-AddPracticeButton-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PracticeManagement-AddPracticeButton-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PracticeManagement-AddPracticeButton-zip-input'
                  label='Zip'
                  name='zip'
                  type='zip'
                  required
                  validations={[validateZipCode()]}
                  format={formatZipCode}
                  maxLength={10}
                />

                <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                  <Grid item xl={11}>
                    <AutoCompleteMultipleSelect
                      name='contactEmails'
                      label='Emails'
                      id='Create-Drug-Profile-AssociatePresDescription-input'
                      freeSolo
                      validations={[validateEmailArray()]}
                      options={[]}
                      defaultValue={[]}
                    />
                  </Grid>

                  <Grid item xl={1} sx={{ textAlign: 'center' }}>
                    <Tooltip
                      title='Type and click enter to add multiple Emails'
                      arrow
                      placement='bottom-end'
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

export default AddPracticeButton;
